@charset "UTF-8";
@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_oodrelpto7.eot");
  /* IE9*/
  src: url("//at.alicdn.com/t/webfont_oodrelpto7.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/webfont_oodrelpto7.woff2") format("woff2"), url("//at.alicdn.com/t/webfont_oodrelpto7.woff") format("woff"), url("//at.alicdn.com/t/webfont_oodrelpto7.ttf") format("truetype"), url("//at.alicdn.com/t/webfont_oodrelpto7.svg#思源黑体-粗") format("svg");
  /* iOS 4.1- */ }

.web-font {
  font-family: "webfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale; }

.sides .left {
  position: fixed;
  top: 50%;
  left: -10px;
  z-index: 132;
  transform: translateY(-50%);
  width: 126px;
  height: 548px; }
  .sides .left .list {
    width: 96px;
    margin: 114px auto 0; }
    .sides .left .list .item {
      width: 92%;
      height: 26px;
      margin: 0 auto 4px; }
      .sides .left .list .item p {
        font-size: 15px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #5E63DB;
        text-align: center;
        line-height: 26px;
        cursor: pointer; }

.sides .hide {
  display: none; }

.sides .right {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 132;
  transform: translateY(-50%);
  width: 84px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.18);
  border-radius: 20px; }
  .sides .right .item {
    width: 100%;
    height: 74px;
    text-align: center;
    border-bottom: 1px solid #E9E9E9;
    cursor: pointer; }
    .sides .right .item:nth-last-child(1) {
      border: none;
      height: 78px; }
    .sides .right .item img {
      width: 30px;
      height: 30px;
      margin: 11px 0 6px; }
    .sides .right .item p {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #464646;
      line-height: 20px; }
